import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      const response = await axios.post('https://backend.yeniesuq.com/api/admin/login', {
        email,
        password,
      });

      const { token } = response.data;

      // Save token to localStorage
      localStorage.setItem('adminToken', token);

      // Update authentication state in the App component
      window.dispatchEvent(new Event('storage'));

      // Navigate to the appropriate page
      navigate('/addproduct');
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setErrorMessage(error.response.data.message || 'Login failed. Please try again.');
      } else {
        setErrorMessage('An error occurred. Please check your internet connection.');
      }
    }
  };


  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-green-400 via-green-500 to-green-600">
      <div className="bg-white p-8 rounded-xl shadow-xl w-full max-w-md">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Admin Login</h2>

        {errorMessage && (
          <div className="mb-4 text-red-500 text-center">{errorMessage}</div>
        )}

        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-lg font-medium text-gray-700">Email Address</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600 transition duration-300"
              placeholder="Enter your email"
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-lg font-medium text-gray-700">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600 transition duration-300"
              placeholder="Enter your password"
              required
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full p-3 bg-green-500 text-white rounded-lg font-medium hover:bg-green-600 focus:outline-none transition duration-300 disabled:opacity-50"
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>

        <div className="mt-6 text-center">
          <p className="text-sm text-gray-500">Forgot your password? <a href="/reset-password" className="text-green-600 hover:underline">Reset it</a></p>
          <p className="text-sm text-gray-500 mt-2">Don't have an account? <a href="/signup" className="text-green-600 hover:underline">Sign up</a></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
