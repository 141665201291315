import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlineShoppingCart, AiOutlineAppstoreAdd ,AiFillProduct, AiFillAccountBook,AiFillContainer} from "react-icons/ai";
import { BsBoxSeam } from "react-icons/bs";

const BottomNav = () => {
  const [activeTab, setActiveTab] = useState("orders");
  const navigate = useNavigate();

  const tabs = [
    { id: "orders", label: "Orders", icon: <BsBoxSeam />, route: "/" },
    { id: "add-product", label: "Add Product", icon: <AiOutlineAppstoreAdd />, route: "/addproduct" },
    { id: "add-categories", label: "Add Cat", icon: <AiFillContainer />, route: "/addcategories" },
    { id: "Product", label: "product", icon: <AiFillProduct />, route: "/productlist" },
  ];

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t flex justify-around items-center h-16 z-10">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`flex flex-col items-center justify-center group transition-all ${
            activeTab === tab.id
              ? "text-green-600 scale-110" // Active tab styling
              : "text-gray-500 hover:text-green-600" // Inactive tab styling
          }`}
          onClick={() => {
            setActiveTab(tab.id);
            navigate(tab.route);
          }}
          aria-label={tab.label} // Improves accessibility
        >
          {/* Icon */}
          <div
            className={`text-2xl mb-1 transform transition-transform ${
              activeTab === tab.id
                ? "text-green-600 scale-125"
                : "text-gray-500 group-hover:text-green-600 group-hover:scale-110"
            }`}
          >
            {tab.icon}
          </div>
          {/* Label */}
          <span
            className={`text-xs font-medium tracking-wide ${
              activeTab === tab.id
                ? "text-green-600 font-bold"
                : "text-gray-500 group-hover:text-green-600"
            }`}
          >
            {tab.label}
          </span>
        </button>
      ))}
      {/* Ripple Effect */}
      <div
        className="absolute bottom-0 w-full h-[2px] bg-green-600 transition-transform duration-300"
        style={{
          transform: `translateX(${tabs.findIndex((tab) => tab.id === activeTab) * 100}%)`,
        }}
      ></div>
    </nav>
  );
};

export default BottomNav;
