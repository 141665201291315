import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Orders from './pages/Orders';
import AddProduct from './pages/AddProduct';
import Products from './pages/Products';
import Shop from './pages/Shop';
import Navbar from './components/Topnav'; // Import Navbar
import BottomNav from './components/BottomNav';
import reportWebVitals from './reportWebVitals';
import Login from './pages/Login';
import AdminOrders from './pages/Orders';
import CategoryForm from './pages/Addcategories';
import SubCategoryForm from './pages/SubCat';
import ProductList from './pages/productlist';
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Track authentication state
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check if user is authenticated
  const checkAuthentication = () => {
    const token = localStorage.getItem('adminToken'); // Check token in localStorage
    console.log('Token in localStorage:', token); // Debugging token
    setIsAuthenticated(!!token); // Set true if token exists, otherwise false
  };

  // Initial check for authentication when app loads
  useEffect(() => {
    checkAuthentication();
  }, []);

  // Handle redirection based on authentication state
  useEffect(() => {
    if (isAuthenticated === false && location.pathname !== '/login') {
      console.log('Redirecting to login');
      navigate('/login');
    } else if (isAuthenticated === true && location.pathname === '/login') {
      console.log('Redirecting to home');
      navigate('/');
    }
  }, [isAuthenticated, navigate, location.pathname]);

  // Listen for changes to `localStorage` (e.g., token changes after login/logout)
  useEffect(() => {
    const handleStorageChange = () => {
      checkAuthentication();
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Show loading spinner while determining authentication state
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="pb-16">
      {/* Show Navbar and BottomNav except on the login page */}
      {location.pathname !== '/login' && <Navbar />}
      {location.pathname !== '/login' && <BottomNav />}

      <Routes>
        <Route path="/" element={<AdminOrders />} />
        <Route path="/subcat" element={<SubCategoryForm />} />
        <Route path="/productlist" element={<ProductList />} />
        <Route path="/addproduct" element={<AddProduct />} />
        <Route path="/products" element={<Products />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/addcategories" element={<CategoryForm />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
};

// Render the app into the root element
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
