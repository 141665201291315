import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-green-700 text-white fixed w-full z-10 top-0 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/" className="text-2xl font-bold">
              YeneSuq
            </Link>
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="flex lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none focus:ring-2 focus:ring-white rounded-md"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>

          {/* Navigation Links - Desktop */}
          <div className="hidden lg:flex space-x-8 items-center">
            <Link
              to="/subcat"
              className="hover:bg-green-600 px-3 py-2 rounded-md text-sm font-medium transition"
            >
              Add Subcategory
            </Link>
            <Link
              to="/addcategories"
              className="hover:bg-green-600 px-3 py-2 rounded-md text-sm font-medium transition"
            >
              Add Category
            </Link>
            <Link
              to="/addproduct"
              className="hover:bg-green-600 px-3 py-2 rounded-md text-sm font-medium transition"
            >
              Add Product
            </Link>
            <Link
              to="/"
              className="hover:bg-green-600 px-3 py-2 rounded-md text-sm font-medium transition"
            >
              Orders
            </Link>
            <Link
              to="/shop"
              className="hover:bg-green-600 px-3 py-2 rounded-md text-sm font-medium transition"
            >
              Shop
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isOpen && (
        <div className="lg:hidden bg-green-800 text-white">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link
              to="/subcat"
              className="block px-3 py-2 rounded-md text-base font-medium hover:bg-green-600 transition"
              onClick={toggleMenu}
            >
              Add Subcategory
            </Link>
            <Link
              to="/addcategories"
              className="block px-3 py-2 rounded-md text-base font-medium hover:bg-green-600 transition"
              onClick={toggleMenu}
            >
              Add Category
            </Link>
            <Link
              to="/addproduct"
              className="block px-3 py-2 rounded-md text-base font-medium hover:bg-green-600 transition"
              onClick={toggleMenu}
            >
              Add Product
            </Link>
            <Link
              to="/"
              className="block px-3 py-2 rounded-md text-base font-medium hover:bg-green-600 transition"
              onClick={toggleMenu}
            >
              Orders
            </Link>
            <Link
              to="/shop"
              className="block px-3 py-2 rounded-md text-base font-medium hover:bg-green-600 transition"
              onClick={toggleMenu}
            >
              Shop
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
