import React, { useState } from 'react';
import axios from 'axios';

const SubCategoryForm = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null); // To store the selected image
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // To manage loading state

  // Handle the image change event
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !image) {
      setMessage('Please fill out all fields.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', image);

    setIsLoading(true); // Show loading spinner

    try {
      // Make a POST request to the backend to create the subcategory
      const response = await axios.post('https://backend.yeniesuq.com/api/subcat/Create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Necessary for image upload
        },
      });
      setMessage('✅ Subcategory created successfully!');
      setName('');
      setImage(null);
    } catch (error) {
      console.error('Error creating subcategory:', error);
      setMessage('❌ Error creating subcategory!');
    } finally {
      setIsLoading(false); // Hide loading spinner
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white shadow-md rounded-md mt-8">
      {/* Title */}
      <h2 className="text-2xl font-bold text-center text-green-700 mb-6">
        Create Subcategory
      </h2>

      {/* Success or Error Message */}
      {message && (
        <p
          className={`text-center mb-4 text-sm font-medium ${
            message.startsWith('✅') ? 'text-green-700' : 'text-red-600'
          }`}
        >
          {message}
        </p>
      )}

      {/* Form */}
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Subcategory Name */}
        <div className="flex flex-col">
          <label className="text-sm font-medium mb-2 text-gray-600">
            Subcategory Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-gray-300 rounded-lg p-3 text-base w-full focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Enter subcategory name"
            required
          />
        </div>

        {/* Subcategory Image */}
        <div className="flex flex-col">
          <label className="text-sm font-medium mb-2 text-gray-600">
            Subcategory Image
          </label>
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            className="border border-gray-300 rounded-lg p-3 text-base w-full focus:outline-none focus:ring-2 focus:ring-green-500"
            required
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-green-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 flex items-center"
            disabled={isLoading}
          >
            {isLoading && (
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
            )}
            {isLoading ? 'Creating...' : 'Create Subcategory'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubCategoryForm;
