import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddProduct = () => {
  const [productData, setProductData] = useState({
    title: "",
    sku: "",
    color: "",
    size: "",
    brand: "",
    price: "",
    description: "",
    catItems: "",
    subcat: "",
    seller_email: "",
  });
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const API_BASE = "https://backend.yeniesuq.com";

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE}/api/catitem`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch subcategories
  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`${API_BASE}/api/subcat`);
        setSubcategories(response.data);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
    const previewUrls = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(previewUrls);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(productData).forEach((key) => {
      formData.append(key, productData[key]);
    });
    images.forEach((image) => {
      formData.append("images", image);
    });

    setLoading(true);
    try {
      await axios.post(`${API_BASE}/api/prod/add`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Product added successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setProductData({
        title: "",
        sku: "",
        color: "",
        size: "",
        brand: "",
        price: "",
        description: "",
        catItems: "",
        subcat: "",
        seller_email: "",
      });
      setImages([]);
      setPreviewImages([]);
    } catch (error) {
      console.error(error);
      toast.error("Error adding product. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-green-50 p-4">
      <ToastContainer />
      <h2 className="text-2xl font-bold text-green-700 text-center mb-4">
        Add Product
      </h2>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-4 shadow-lg rounded-lg max-w-lg mx-auto"
        encType="multipart/form-data"
      >
        <div className="space-y-4">
          {[{ label: "Title", name: "title", type: "text" },
          { label: "SKU", name: "sku", type: "text" },
          { label: "Color", name: "color", type: "text" },
          { label: "Size", name: "size", type: "text" },
          { label: "Brand", name: "brand", type: "text" },
          { label: "Price", name: "price", type: "number" },
          { label: "Seller Email", name: "seller_email", type: "email" },
          ].map(({ label, name, type }) => (
            <div key={name}>
              <label htmlFor={name} className="block text-sm text-green-700">
                {label}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                value={productData[name]}
                onChange={handleChange}
                required={name !== "sku"}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-green-400"
              />
            </div>
          ))}
          <div>
            <label htmlFor="catItems" className="block text-sm text-green-700">
              Category
            </label>
            <select
              id="catItems"
              name="catItems"
              value={productData.catItems}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-green-400"
            >
              <option value="">Select Category</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="subcat" className="block text-sm text-green-700">
              Subcategory
            </label>
            <select
              id="subcat"
              name="subcat"
              value={productData.subcat}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-green-400"
            >
              <option value="">Select Subcategory</option>
              {subcategories.map((subcat) => (
                <option key={subcat.id} value={subcat.id}>
                  {subcat.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="description"
              className="block text-sm text-green-700"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={productData.description}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-green-400"
            ></textarea>
          </div>
          <div>
            <label htmlFor="image" className="block text-sm text-green-700">
              Product Images
            </label>
            <input
              type="file"
              id="image"
              name="image"
              multiple
              onChange={handleImageChange}
              required
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-green-400"
            />
            <div className="mt-4 flex flex-wrap gap-4">
              {previewImages.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Preview ${index}`}
                  className="w-20 h-20 object-cover rounded-md shadow-md"
                />
              ))}
            </div>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-green-700 text-white py-2 rounded-md hover:bg-green-800 focus:ring-4 focus:ring-yellow-300"
          >
            {loading ? "Adding..." : "Add Product"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
