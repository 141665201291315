import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CategoryForm = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch subcategories from the backend
  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get('https://backend.yeniesuq.com/api/subcat');
        setSubcategories(response.data);
      } catch (error) {
        setError('Failed to fetch subcategories. Please try again.');
      }
    };

    fetchSubcategories();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const trimmedName = name.trim();
    if (!trimmedName) {
      setError('Category name is required.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('name', trimmedName);
    formData.append('image', image);
    selectedSubcategories.forEach((id) => {
      formData.append('subcatIds[]', id);
    });

    try {
      const response = await axios.post(
        'https://backend.yeniesuq.com/api/catitem/create',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      alert(response.data.message || 'Category created successfully!');
      setName('');
      setImage(null);
      setSelectedSubcategories([]);
    } catch (error) {
      setError('Failed to create category.');
    } finally {
      setLoading(false);
    }
  };

  // Add or remove subcategories
  const handleAddSubcategory = (id) => {
    if (!selectedSubcategories.includes(id)) {
      setSelectedSubcategories([...selectedSubcategories, id]);
    }
  };

  const handleRemoveSubcategory = (id) => {
    setSelectedSubcategories(selectedSubcategories.filter((subcatId) => subcatId !== id));
  };

  return (
    <div className="max-w-3xl mx-auto p-8 bg-gradient-to-br from-green-50 to-green-100 shadow-lg rounded-xl">
      <h2 className="text-1xl font-bold text-green-700 mb-6">Create Category</h2>

      {/* Error Alert */}
      {error && (
        <div className="mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        {/* Category Name */}
        <div className="mb-6">
          <label className="block font-semibold text-gray-700 mb-2">Category Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400"
            placeholder="Enter category name"
            required
          />
        </div>

        {/* Category Image */}
        <div className="mb-6">
          <label className="block font-semibold text-gray-700 mb-2">Category Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="w-full text-sm file:bg-green-500 file:text-white file:rounded-lg file:py-2 file:px-4 file:cursor-pointer hover:file:bg-green-600"
          />
        </div>

        {/* Subcategories */}
        <div className="mb-6">
          <label className="block font-semibold text-gray-700 mb-2">Subcategories</label>
          <select
            onChange={(e) =>
              handleAddSubcategory(Number(e.target.value))
            }
            className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400"
          >
            <option value="">Select a Subcategory</option>
            {subcategories.map((subcat) => (
              <option key={subcat.id} value={subcat.id}>
                {subcat.name}
              </option>
            ))}
          </select>

          {/* Selected Subcategories */}
          <div className="mt-4 flex flex-wrap gap-2">
            {selectedSubcategories.map((id) => {
              const subcat = subcategories.find((sub) => sub.id === id);
              return (
                <div
                  key={id}
                  className="flex items-center bg-green-200 text-green-800 px-3 py-1 rounded-full shadow-md"
                >
                  {subcat.name}
                  <button
                    type="button"
                    onClick={() => handleRemoveSubcategory(id)}
                    className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                  >
                    &times;
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className={`w-full py-3 px-4 font-semibold text-white rounded-lg shadow-md transition-all ${
            loading
              ? 'bg-green-300 cursor-not-allowed'
              : 'bg-green-500 hover:bg-green-600'
          }`}
          disabled={loading}
        >
          {loading ? 'Creating...' : 'Create Category'}
        </button>
      </form>
    </div>
  );
};

export default CategoryForm;
