import React, { useEffect, useState } from "react";
import axios from "axios";

const apiUrl = "https://backend.yeniesuq.com"; // Backend URL

// Fetch all orders
const fetchAllOrders = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/payments/orders`);
    return response.data.orders;
  } catch (error) {
    console.error(
      "Error fetching orders:",
      error.response ? error.response.data : error.message
    );
    throw new Error("Failed to fetch orders");
  }
};

// Update payment status
const updatePaymentStatus = async (paymentId, status) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/payments/update-status/${paymentId}`,
      { payment_status: status }
    );
    return response.data.payment;
  } catch (error) {
    console.error(
      "Error updating payment status:",
      error.response ? error.response.data : error.message
    );
    throw new Error("Failed to update payment status");
  }
};

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getOrders = async () => {
      try {
        const ordersData = await fetchAllOrders();
        setOrders(ordersData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getOrders();
  }, []);

  const handleStatusChange = async (paymentId, newStatus) => {
    try {
      const updatedPayment = await updatePaymentStatus(paymentId, newStatus);
      setOrders(
        orders.map((order) =>
          order.id === updatedPayment.id ? updatedPayment : order
        )
      );
      setShowModal(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const openModal = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedOrder(null);
    setShowModal(false);
  };

  if (loading) {
    return <div className="text-center p-6">Loading orders...</div>;
  }

  if (error) {
    return <div className="text-center text-red-600 p-6">Error: {error}</div>;
  }

  return (
    <div className="p-4 bg-green-50">
      <h1 className="text-2xl font-bold text-green-700 mb-4">All Orders</h1>
      <ul className="space-y-4">
        {orders.map((order) => (
          <li
            key={order.id}
            className="border rounded-lg shadow-sm p-4 bg-white"
          >
            <p>
              <strong>Order ID:</strong> {order.id}
            </p>
            <p>
              <strong>Customer Name:</strong> {order.customer_name}
            </p>
            <p>
              <strong>Email:</strong> {order.customer_email}
            </p>
            <p>
              <strong>Payment Status:</strong>{" "}
              <span className="text-yellow-600">{order.payment_status}</span>
            </p>
            <p>
              <strong>Total Price:</strong>{" "}
              <span className="text-green-700">${order.total_price}</span>
            </p>
            <button
              onClick={() => openModal(order)}
              className="mt-2 bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded"
            >
              View Details
            </button>
          </li>
        ))}
      </ul>

      {/* Modal */}
      {showModal && selectedOrder && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-md max-h-screen overflow-y-auto p-6 relative">
            <button
              onClick={closeModal}
              className="absolute top-3 right-3 bg-red-500 hover:bg-red-600 text-white rounded-full w-8 h-8 flex items-center justify-center"
            >
              X
            </button>
            <h2 className="text-xl font-bold text-green-700 mb-4">
              Order Details
            </h2>
            <p>
              <strong>Order ID:</strong> {selectedOrder.id}
            </p>
            <p>
              <strong>Customer Name:</strong> {selectedOrder.customer_name}
            </p>
            <p>
              <strong>Email:</strong> {selectedOrder.customer_email}
            </p>
            <p>
              <strong>Payment Status:</strong>{" "}
              <span className="text-yellow-600">
                {selectedOrder.payment_status}
              </span>
            </p>
            <p>
              <strong>Total Price:</strong>{" "}
              <span className="text-green-700">${selectedOrder.total_price}</span>
            </p>
            <p>
              <strong>Shipping Address:</strong>{" "}
              {selectedOrder.shipping_address}
            </p>
            <p>
              <strong>Payment Method:</strong> {selectedOrder.payment_method}
            </p>
            <p>
              <strong>Cart Items:</strong>
            </p>
            <ul className="list-disc ml-6 text-gray-700">
              {JSON.parse(selectedOrder.cart_items).map((item, index) => (
                <li key={item.id || index}>
                  <strong>Title:</strong> {item.title} |{" "}
                  <strong>Price:</strong> ${item.price} |{" "}
                  <strong>Quantity:</strong> {item.quantity}
                </li>
              ))}
            </ul>
            <div className="mt-4">
              <strong>Screenshot:</strong>
              <div className="mt-2">
                <img
                  src={`${apiUrl}/${selectedOrder.payment_screenshot}`}
                  alt="Payment Screenshot"
                  className="rounded-lg shadow-sm"
                />
              </div>
            </div>
            <div className="mt-4 flex gap-4">
              <button
                onClick={() => handleStatusChange(selectedOrder.id, "Approved")}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
              >
                Approve
              </button>
              <button
                onClick={() => handleStatusChange(selectedOrder.id, "Declined")}
                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminOrders;
