import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    sku: "",
    color: "",
    size: "",
    brand: "",
    price: "",
    description: "",
    catItems: "",
    subcat: "",
    seller_email: "",
  });

  const API_BASE = "https://backend.yeniesuq.com";

  // Fetch products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE}/api/prod`);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
        toast.error("Failed to fetch products.");
      }
    };

    fetchProducts();
  }, []);

  // Delete product
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this product?")) return;

    try {
      await axios.delete(`${API_BASE}/api/prod/${id}`);
      setProducts(products.filter((product) => product.id !== id));
      toast.success("Product deleted successfully.");
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Failed to delete product.");
    }
  };

  // Edit product
  const handleEditClick = (product) => {
    setEditingProduct(product.id);
    setFormData({ ...product });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${API_BASE}/api/prod/${editingProduct}`, formData);
      setProducts(
        products.map((product) =>
          product.id === editingProduct ? { ...formData, id: editingProduct } : product
        )
      );
      setEditingProduct(null);
      toast.success("Product updated successfully.");
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Failed to update product.");
    }
  };

  return (
    <div className="p-4">
      <ToastContainer />
      <h2 className="text-2xl font-bold text-center mb-4">Product List</h2>

      {editingProduct && (
        <form
          onSubmit={handleEditSubmit}
          className="bg-white p-4 shadow-md rounded-lg max-w-lg mx-auto mb-4"
        >
          <h3 className="text-xl font-semibold mb-4">Edit Product</h3>
          {["title", "sku", "color", "size", "brand", "price", "description", "catItems", "subcat", "seller_email"].map((field) => (
            <div key={field} className="mb-3">
              <label
                htmlFor={field}
                className="block text-sm font-medium text-gray-700"
              >
                {field.charAt(0).toUpperCase() + field.slice(1)}
              </label>
              <input
                type={field === "price" ? "number" : "text"}
                id={field}
                name={field}
                value={formData[field]}
                onChange={handleEditChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-green-400"
              />
            </div>
          ))}
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => setEditingProduct(null)}
              className="px-4 py-2 bg-gray-500 text-white rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 text-white rounded-md"
            >
              Save Changes
            </button>
          </div>
        </form>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border">ID</th>
              <th className="px-4 py-2 border">Title</th>
              <th className="px-4 py-2 border">Price</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td className="px-4 py-2 border">{product.id}</td>
                <td className="px-4 py-2 border">{product.title}</td>
                <td className="px-4 py-2 border">${product.price.toFixed(2)}</td>
                <td className="px-4 py-2 border flex gap-2 justify-center">
                  <button
                    onClick={() => handleEditClick(product)}
                    className="px-2 py-1 bg-blue-600 text-white rounded-md"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(product.id)}
                    className="px-2 py-1 bg-red-600 text-white rounded-md"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductList;
